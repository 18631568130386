.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* blog slider */
.card {
  display: inline-block;
  float: left;
}

.logo-icon {
display: inline-block;
}
.logo-slider {
display: block;
margin: 0 auto;
text-align: center;
}
.logo-slider i {
margin: 0 20px;
}

/* blog slider */
.card {
display: inline-block;
float: left;
}

.sdcom-spinner .spinner {
  color: #0069fa;
  font-size: 30px;
}

.spinner-text {
  color: #0069fa;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: darkblue;
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(34, 100, 255, 1) 1.5em 0 0 0, rgba(34, 100, 255, 0.9) 1.1em 1.1em 0 0, rgba(34, 100, 255, 0.8) 0 1.5em 0 0, rgba(34, 100, 255, 0.7) -1.1em 1.1em 0 0, rgba(34, 100, 255, 0.6) -1.5em 0 0 0, rgba(34, 100, 255, 0.5) -1.1em -1.1em 0 0, rgba(34, 100, 255, 0.4) 0 -1.5em 0 0, rgba(34, 100, 255, 0.3) 1.1em -1.1em 0 0;
  box-shadow: rgba(34, 100, 255, 1) 1.5em 0 0 0, rgba(34, 100, 255, 0.9) 1.1em 1.1em 0 0, rgba(34, 100, 255, 0.8) 0 1.5em 0 0, rgba(34, 100, 255, 0.7) -1.1em 1.1em 0 0, rgba(34, 100, 255, 0.6) -1.5em 0 0 0, rgba(34, 100, 255, 0.5) -1.1em -1.1em 0 0, rgba(34, 100, 255, 0.4) 0 -1.5em 0 0, rgba(34, 100, 255, 0.3) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}