.blog-page-wrap {
	padding-bottom: 50px;
  .blog-page {
    .row {
      display: inline-block;
    }
    .blog-heading {
      font-weight: 600;
      font-size: 17px;
      color: $blue;
    }
    .blog-grid {
      padding-bottom: 30px;
      display: inline-block;
      .blog-image {
        display: inline-block;
        width: 50%;
        float: left;
        margin: 15px 15px 15px 0;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
      .title {
        padding: 10px 0;
      }
      .blog-desc {
        padding-bottom: 15px;
        .btn {
          padding: 5px 10px;
          text-transform: none;
          margin: 0;
        }
      }
      .auth-info {
        display: inline-block;
        width: 100%;
        float: left;
        padding: 5px 0;
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
        img {
          height: 25px;
          width: 25px;
        }
        p {
          margin-bottom: 0;
        }
        .auth-name {
          padding-right: 10px;
        }
        .publish-info {
          display: inline-block;
          .one-dot {
            display: inline-block;
          }
        }
      }
    }
    .col-md-6{
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        display: inline-block;
        float: right;
      }
      @media screen and (max-width: 1024px)  {
        float: left;
      }
      &:first-child {
        @media screen and (min-width: 767px) and (max-width: 1200px) {
          display: inline-block;
          float: left;
        }
        .blog-grid {
          float: left;
          .blog-image {
            width: 100%;
          }
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        float: right;
        height: fit-content;
        margin-bottom: 18px
      }
      &:nth-child(2n) {
        float: right;
        @media screen and (min-width: 767px) and (max-width: 1200px){
          display: inline-block;
          float: left;
        }
      }
    }
  }
}

