/* --------------------------------------------------
  Form styling
-------------------------------------------------- */

.contact-us {
  form {
    display: block;
    max-width: 650px;
    margin: 0 auto;
    padding: 40px;
    .form-control {
      background-image: none;
    }
  }
}
