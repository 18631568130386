/*@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/open-sans/OpenSans-Regular.ttf');
}
*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,700i,800');

body {
  background-color: $white;
  color: $dark-grey;
  font-size: 16px;
  line-height: 23px;
  font-family: 'Open Sans';
  @media only screen and (max-width: 992px) {
    margin-top: 65px;

  }
}

.hover-text-blue {
  color: #0000CD;
}
.blue {
  color: $blue;
}
.bg-blue {
  background-color: $blue;
  color: $white;
}

.bg-sky-blue {
  background-color: $sky-blue;
}
.bold {
  font-weight: bold;
}

.grey {
  color: $grey;
}

.center-wrapper {
  display: flex;
  align-items: center;
}
.rounded-circle {
  img {
    max-width: 150px;
    margin: 0 auto;
    border-radius: 50%;
  }
}

.box-shadow {
  @include prefix('box-shadow', rgb(000, 000, 000) 0 3px 34px);
}

.popup-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

span:focus {
  outline: none;
}
section {
  padding: 70px 0 0;
}

button:focus {
  outline: none;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
