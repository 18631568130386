/* --------------------------------------------------
	0.1 Settigns
-------------------------------------------------- */



/* ---- Colors ---- */
$white: #FFF;
$black: #000;

$blue: #2264FF;
$sky-blue: #E1ECFF;

$grey: #C2C2C2;
$dark-grey: #3C3C3C;
$light-grey: #E1E1E1;

$blue-line: #8EA6FF;
/* ---- Text ---- */

// Font Family
$body-font: "Axiforma", Helvetica, Arial, sans-serif;
$heading-font: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;
$alt-heading-font: "Pensum", serif;
$alt-heading-font1: 'Fira Sans', 'Helvetica Neue', Helvetica, sans-serif;


/* ---- White Space and widths---- */
$ws-xs: 50px;
$ws-s: 65px;
$ws-m: 100px;
$ws-l: 160px;


/* ---- Other ---- */
$hr-color: #CCC;
$name: 'animate';

/* ---- Media Queries Breakpoints ---- */
$screen-xs: 600px;
$screen-sm: 770px;
$screen-md: 992px;
$screen-lg: 1260px;
$screen-xlg: 1440px;

