/* --------------------------------------------------
   Components
-------------------------------------------------- */

.cross-wrap {
  display: inline-block;
  width: 100%;
  .cross-btn {
    margin: 20px 15px;
    span {
      height: 35px;
      width: 35px;
      padding: 3px 13px;
      background-color: $grey;
      color: $white;
      @include prefix('border-radius', 100%);
    }
  }
}

.auth-info {
  padding: 10px 0;
  img {
    float: left;
    display: inline-block;
    height: 40px;
    width: 40px;
    @include prefix('border-radius', 100%);
    margin-right: 10px;
  }
  .auth-name {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
  }
}
.one-dot {
  height: 7px;
  width: 7px;
  margin: 2px 20px;
  background-color: $blue;
  @include prefix('border-radius', 50%);
}

.after-right::after {
  position: absolute;
  top: 0;
  display: inline-block;
  height: 70px;
  border-right: 5px solid $blue-line;
  content: '';
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.before-bottom::before {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: inline-block;
  height: 80px;
  width: 105px;
  margin-bottom: 50px;
  border-bottom: 5px solid $blue-line;
  content: '';
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

/* popup boxes */
.popup-modal {
  .modal-dialog {
    display: inline-block;
    max-width: calc(1060px - 125px);
    width: 100%;
    text-align: left;
    @media only screen and (max-width: 992px) {
      max-width: 80%;
    }
    .icon-popup {
      z-index: 1;
      display: flex;
      height: 245px;
      width: 245px;
      margin-top: -100px;
      margin-left: -95px;
      padding: 10px;
      background-color: $blue;
      text-align: center;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      @include prefix('border-radius', 100%);
      @media only screen and (max-width: 767px) {
        height: 190px;
        width: 190px;
        margin-top: -90px;
        margin-left: -35px;
      }
      @media only screen and (max-width: 580px) {
        margin-top: -100px;
      }
      img {
        max-width: 100%;
        vertical-align: middle;
      }
      i {
        color: $white;
        font-size: 90px;
      }
    }
    .modal-title,
    .modal-sub-heading {
      @extend .blue;
      @extend .bold;
    }
    .modal-sub-heading {
      font-size: 22px;
    }
    .modal-content {
      margin-top: 80px;
      @include prefix('border-radius', 20px);
      .modal-header {
        padding-bottom: 0;
        border-bottom: none !important;
        button {
          span {
            height: 35px;
            width: 35px;
            padding: 3px 13px;
            background-color: $grey;
            color: $white;
            @include prefix('border-radius', 100%);
          }
          :focus {
            outline: none !important;
          }
        }
      }
    }
  }
  .dots {
    height: 125px;
    width: auto;
    margin: 10px 20px;
    background-image: url('../assets/image/dots.png');
    background-repeat: no-repeat;
    @media only screen and (max-width: 992px) {
      width: 65px;
    }
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
}

.sd-about.after-right::after {
  margin-left: 100px;
}

.modal-title,
.modal-sub-heading {
  @extend .blue;
  @extend .bold;
}

/* banner (about-sd)*/
.sd-about {
  position: relative;
  .col-md-6 {
    position: static;
  }
  .about-left {
    display: inline-block;
    max-width: 500px;
    width: 100%;
    @media only screen and (max-width: 767px) {
      margin-top: 40px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      height: 100%;
      max-width: 35%;
      width: 100%;
      background-color: $blue;
      content: '';
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    img {
      position: relative;
      float: right;
      display: inline-block;
      max-width: 100%;
      margin-top: 100px;
      margin-bottom: 100px;
      @extend .box-shadow;
      @media only screen and (max-width: 992px) {
        margin-top: 60px;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
  .about-right {
    display: block;
    max-width: 550px;
    margin: 100px auto;
    @media only screen and (max-width: 767px) {
      display: inline-block;
      width: 100%;
      margin: 70px auto;
    }
    .header-wrap {
      display: flex;
      height: 100%;
      padding-bottom: 15px;
      font-weight: bold;
      vertical-align: middle;
      flex-wrap: wrap;
      align-items: center;
      @media only screen and (max-width: 767px) {
        display: inline-block;
      }
      h1 {
        display: inline-block;
        max-width: 250px;
        border-right: 3px solid $dark-grey;
        font-weight: bold;
        @media only screen and (max-width: 767px) {
          max-width: unset;
          border: none;
        }
      }
      p {
        @media only screen and (min-width: 1200px) {
          padding: 0 30px;
        }
      }
    }
    .sd-post {
      ul {
        @extend .blue;
        display: inline-block;
        width: 100%;
        padding-left: 0;
        font-weight: bold;
        li {
          display: inline-block;
        }
      }
    }
    .sd-content {
      p {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    .social-icons {
      position: absolute;
      top: 80%;
      bottom: 0;
      display: inline-block;
      @media only screen and (max-width: 767px) {
        position: unset;
      }
      ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          list-style-type: none;
        }
      }
      a {
        display: inline-block;
        i {
          height: 50px;
          width: 50px;
          margin: 5px;
          padding: 10px;
          @include prefix('border-radius', 100%);
          background-color: $light-grey;
          color: $white;
          font-size: 28px;
          text-align: center;
        }
        :hover {
          background-color: $blue;
          color: $white;
        }
      }
    }
  }
}

/* industries */
.industries-wrap {
  position: relative;
  &::before {
    position: absolute;
    top: -43px;
    left: 0;
    z-index: -1;
    display: inline-block;
    height: 100px;
    width: 100%;
    background-image: url('../assets/image/shadow1.png');
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
  }
  .industries {
    cursor: pointer;
    img {
      height: 74px;
      max-width: 80px;
      margin: 30px;
    }
    h5 {
      @extend .blue;
      font-weight: bold;
    }
    :hover {
      @extend .hover-text-blue;
    }
  }
  .popup-modal {
    .modal-dialog {
      .icon-popup {
        height: 229px;
        width: 223px;
        margin-top: -79px;
        @media only screen and (max-width: 992px) {
          max-height: 190px;
          max-width: 190px;
        }
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
    .modal-content {
      max-width: 80%;
      margin: 0 auto;
      margin-top: 70px;
      justify-content: center;
    }
  }
}

.portfolio-wrap {
  position: relative;
  .vertical-name {
    transform: rotate(-90deg) translate(0, 165px);
  }
  .row {
    .card {
      cursor: pointer;
      img {
        height: 200px;
        max-width: 300px;
        @media only screen and (max-width: 1200px) {
          height: 150px;
          max-width: 200px;
        }
        @media only screen and (max-width: 992px) {
          height: 200px;
          max-width: 300px;
        }
      }
      .body-wrap {
        display: inline-block;
        padding: 15px;
        a {
          color: $black;
          font-size: 22px;
          font-weight: bold;
          text-decoration: none;
          text-transform: uppercase;
          &:hover {
            color: $blue;
          }
        }
      }
    }
    .col-lg-4:nth-child(2n + 1) {
      .card {
        background-color: $sky-blue;
      }
    }
    @media only screen and (max-width: 992px) {
      .col-lg-4:nth-child(2n + 2) {
        .card {
          background-color: $sky-blue;
        }
      }
    }
  }
  .popup-modal {
    .modal-header {
      margin-top: -60px;
      padding-top: 0;
    }
    .modal-body {
      ul {
        padding-left: 0;
        li {
          list-style-type: none;
          &::before {
            display: inline-block;
            height: 9px;
            width: 9px;
            margin: 2px 12px;
            padding: 5px;
            background-color: $black;
            @include prefix('border-radius', 100%);
            content: '';
          }
        }
      }
    }
    .modal-dialog {
      width: calc(1060px - 130px);
    }
    .dots {
      position: relative;
      height: 125px;
      margin-top: -45px;
      margin-bottom: 0;
      background-position: right;
      background-repeat: no-repeat;
    }
  }
  .icon-popup {
    position: absolute;
    border: 6px solid $blue !important;
    background-color: $white !important;
    img {
      max-width: 180px !important;
      width: 100%;
    }
  }
  .modal-image {
    position: relative;
    display: block;
    text-align: center;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: inline-block;
      height: 157px;
      width: 100%;
      margin-top: -56px;
      background-image: url('../assets/image/Shape-circle.png');
      background-position: center;
      background-repeat: no-repeat;
      content: '';
    }
    img {
      max-width: 100%;
    }
  }
  .technology-used-wrap {
    display: block;
    max-width: 770px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    .col-md-4 {
      padding: 0;
    }
    .line-right {
      height: 100%;
      border-right: 2px solid $blue;
      @media only screen and (max-width: 767px) {
        border-right: none;
      }
    }
  }
  .col-md-4:last-child .line-right {
    border-right: none;
  }
}

/*testimonial section */
.testimonial-wrap {
  position: relative;
  display: inline-block;
  width: 100%;
  background-image: url('../assets/image/dash.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  .vertical-name {
    transform: rotate(-90deg) translate(0, -225px);
  }
}
.testimonial {
  display: block;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  h3 {
    padding: 10px 0;
    font-weight: bold;
  }
  ul {
    display: inline-block;
    width: 100%;
    padding: 0;
    @extend .grey;
    li {
      display: inline-block;
      padding: 0 15px;
      text-align: center;
    }
    .one-dot {
      height: 2px;
      width: 2px;
      margin: 0;
      padding: 3px;
      background-color: $light-grey;
    }
  }
}

/*blog section */
.slider {
  .card {
    padding: 0;
  }
  .btn {
    display: block;
    width: fit-content;
    margin: 20px auto;
    justify-content: center;
  }
}

/* slider arrows */
.owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  .owl-prev,
  .owl-next {
    float: left;
    span {
      height: 20px;
      width: 20px;
      color: #2264FF;
      font-size: 58px;
      font-weight: 500;
    }
  }
  .owl-next {
    float: right;
  }
}

/* logo slider */

.capailities-wrap {
  .logo-slider {
    padding: 80px 0;
    i {
      color: $blue;
      font-size: 80px;
      cursor: pointer;
      opacity: .5;
      &:hover {
        font-size: 90px;
        opacity: 1;
        transition: .5s ease 0s all;
      }
    }
    .owl-stage-outer {
      z-index: 99;
      min-height: 92px;
    }
    .item {
      display: flex;
      min-height: 90px;
      text-align: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }
  .modal-header {
    padding: 0 20px !important;
  }
  .portfolio-image-wrap {
    .card {
      display: flex;
      height: 100%;
      background-color: $sky-blue;
      flex-wrap: wrap;
      h4 {
        @extend .bold;
        margin: 10px 0;
      }
    }
  }
  .owl-nav {
    .owl-prev {
      margin-left: -75px;
      @media only screen and (max-width: 1280px) {
        margin-left: -30px;
      }
      @media only screen and (max-width: 620px) {
        margin-left: 0;
      }
    }
    .owl-next {
      margin-right: -75px;
      @media only screen and (max-width: 1280px) {
        margin-right: -30px;
      }
      @media only screen and (max-width: 620px) {
        margin-right: 0;
      }
    }
  }
  .popup-modal {
    .modal-dialog {
      @media only screen and (max-width: 1100px) {
        max-width: calc(1060px - 275px);
      }
      @media only screen and (max-width: 992px) {
        max-width: calc(1060px - 400px);
      }
      .icon-popup {
        @media only screen and (max-width: 1100px) {
          height: 186px;
          width: 186px;
          margin-left: -55px;
        }
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

#owl-demo .item img {
  display: block;
  height: auto;
  width: 100%;
}

/* blog section */
.blog-wrap {
  position: relative;
  padding-bottom: 70px;
  .vertical-name {
    transform: rotate(-90deg) translate(0, 75px);
  }
}

.card {
  .card-body {
    cursor: pointer;
    .card-title {
      display: inline-block;
      min-height: 80px;
      font-size: 22px;
      font-weight: bold;
    }
    a {
      color: $black;
      text-decoration: none;
      &:hover {
        color: $blue;
      }
    }
    .auth-info {
      padding: 10px 0;
      img {
        float: left;
        display: inline-block;
        height: 40px;
        width: 40px;
        @include prefix('border-radius', 100%);
        margin-right: 10px;
      }
      .auth-name {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .publish-info {
      display: inline-block;
      width: 100%;
      padding: 10px 0 0;
      color: $grey;
      font-size: 16px;
      font-weight: bold;
      .one-dot {
        display: inline-block;
        background-color: $light-grey;
      }
    }
  }
}

.blog-slider {
  .owl-nav {
    .owl-prev {
      margin-left: -60px;
      @media only screen and (max-width: 1280px) {
        margin-left: -30px;
      }
      @media only screen and (max-width: 620px) {
        margin-left: 0;
      }
    }
    .owl-next {
      margin-right: -60px;
      @media only screen and (max-width: 1280px) {
        margin-right: -30px;
      }
      @media only screen and (max-width: 620px) {
        margin-right: 0;
      }
    }
  }
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: none;
  color: #FFF;
  text-decoration: none;
}
/* connect section*/
.contact-us {
  padding: 0;
  .col-md-7 {
    margin: 0;
    padding: 0;
  }
  .left-connect {
    background-color: $sky-blue;
    background-image: url('../assets/image/Banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    h2 {
      padding-bottom: 15px;
      color: $blue;
      line-height: 40px;
      font-weight: 600;
    }
  }
  .main-heading {
    margin: 10px 20px;
    @media only screen and (max-width: 767px) {
      padding-top: 15px;
    }
  }
  .icon-wrap {
    padding: 0 0 15px;
    .fa {
      padding: 16px;
      @include prefix('border-radius', 100%);
      background-color: $white;
      color: $blue;
      font-size: 40px;
    }
    h4 {
      padding: 10px 0 0;
      font-weight: 600;
      @media only screen and (max-width: 992px) {
        font-size: 18px;
      }
    }
  }
  .center-wrapper {
    border-bottom: 1px solid $white;
  }
  h4 {
    font-weight: bold;
  }
  .after-right {
    position: relative;
  }
  .before-bottom::before {
    right: 0;
    bottom: 0;
    left: unset;
    width: 80px;
    margin-bottom: 60px;
  }
  .after-right::after {
    position: absolute;
    top: 0;
    right: 0;
    left: unset;
    margin-right: 70px;
    @media only screen and (max-width: 1200px) {
      margin-right: 50px;
    }
    @media only screen and (max-width: 992px) {
      margin-right: 30px;
    }
  }
  .main-heading {
    margin-bottom: 20px;
  }
  .connect-url {
    display: inline-block;
    width: 100%;
    padding: 10px 0;
    color: $white;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    @media only screen and (max-width: 1080px) {
      font-size: 18px;
    }
  }
}

footer {
  padding: 15px 0;
}

.ReactModal__Overlay {
  z-index: 999;
}
.ReactModal__Content {
  min-height: 300px;
  max-width: 500px;
  width: 100%;
}

.ReactModal__Content {
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  margin-right: -50%;
  padding: 20px;
  overflow: auto;
  border-radius: 20px;
  background: rgb(255, 255, 255);
  color: blue;
  text-align: center;
  outline: none;
  transform: translate(-50%, -50%);
  @extend .popup-shadow;
  img {
    display: inline-block;
    height: 150px;
    margin: 0 0 20px;
    border-radius: 50%;
    @extend .popup-shadow;
  }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
    opacity: 1;
  }
  .cross-wrap {
    span {
      background-color: blue;
      @extend .popup-shadow;
    }
    .cross-btn {
      margin: 5px 0;
    }
  }
}
