/* --------------------------------------------------
 Header Style
-------------------------------------------------- */
.navbar {
  right: 0;
  padding: 0;
  box-shadow: none;
  @media only screen and (max-width: 992px) {
    padding: 10px 0;
    button {
      background-color: $blue;
      .navbar-toggler-icon {
        filter: brightness(0) invert(1);
      }
    }
  }
}
.sd-navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  @include prefix(transition, .5s ease 0s all);
  @media only screen and (max-width: 992px) {
    background-color: $white;
    ul {
      li {
        margin-left: 0 !important;
        padding-left: 0;
      }
    }
  }
  ul {
    padding: 20px 0;
  }
  .navbar-light {
    .navbar-nav {
      .nav-link {
        @extend .blue;
        font-size: 16px;
        font-weight: 700;
      }
      .nav-item {
        border-bottom: transparent;
        &:hover {
          border-bottom: 2px solid $blue;
        }
      }
      .active {
        border-bottom: 2px solid $blue;
      }
    }
  }
  .after::after {
    right: unset !important;
    left: 10px;
  }
  .uname {
    /*display: none;*/
    height: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .5);
    opacity: 0;
    visibility: hidden;
    @include prefix(transition, .5s ease 0s all);
  }
  &.fix-logo {
    .uname {
      /*display: block;*/
      height: auto;
      opacity: 1;
      visibility: visible;
    }
  }
}

.fixed-header {
  background-color: $white;
}
